import { type VuetifyOptions } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import { de } from 'vuetify/locale';

const inputDefault = {
  variant: 'outlined',
  density: 'comfortable',
};

export const vuetifyOptionsS21: VuetifyOptions = {
  locale: {
    locale: 'de',
    fallback: 'en',
    messages: { de },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#da211d',
          'on-primary': '#FFFFFF',

          // secondary Color
          // secondary: '#dedede',
          // 'on-secondary': '#000000',
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: '#da211d',
          'on-primary': '#FFFFFF',

          // secondary Color
          // secondary: '#424242',
          // 'on-secondary': '#FFFFFF',
        },
      },
    },
  },
  defaults: {
    VAlert: {
      variant: 'tonal',
      VBtn: {
        variant: 'tonal',
      },
    },
    VAppBar: {
      elevation: 0,
    },
    VCard: {
      elevation: 0,
      border: true,
      VCardActions: {
        VBtn: {
          variant: 'flat',
        },
      },
    },
    VMenu: {
      VCard: {
        elevation: 8,
        border: false,
      },
    },
    VBtn: {
      elevation: 0,
    },
    VCheckbox: {
      color: 'primary',
    },
    VCombobox: {
      ...inputDefault,
      delimiters: [','],
    },
    VTextField: {
      ...inputDefault,
    },
    VTextarea: {
      ...inputDefault,
    },
    VSelect: {
      ...inputDefault,
    },
    VAutocomplete: {
      ...inputDefault,
    },
    VSwitch: {
      ...inputDefault,
      inset: true,
      color: 'primary',
    },
    VFileInput: {
      ...inputDefault,
    },
    VDialog: {
      maxWidth: 500,
      VCard: {
        VCardActions: {
          class: 'justify-end',
        },
      },
    },
    VDataTable: {
      VBtn: {
        size: 'small',
        VIcon: {
          size: 'x-large',
        },
      },
    },
    VDataTableServer: {
      VBtn: {
        size: 'small',
        VIcon: {
          size: 'x-large',
        },
      },
    },
    VTooltip: {
      location: 'bottom center',
    },
  },
};
