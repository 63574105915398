import { computed } from 'vue';
import { type RouteRecordRaw } from 'vue-router';

import { useGlobalI18n } from '@abaninja/util-i18n';

const { global: { t } } = useGlobalI18n();

export const routes: RouteRecordRaw[] = [
  {
    path: 'settings',
    children: [
      {
        path: '',
        component: () => import('../SettingsIndex.vue'),
        name: 'settings',
        children: [
          {
            path: 'company-profile',
            name: 'settings-company-profile',
            meta: {
              title: computed(() => t('messages.companyProfile')),
              permissions: ['ADMIN'],
            },
            component: () => import('../components/CompanyProfileIndex.vue'),
          },
          {
            path: 'user-management',
            name: 'settings-user-management',
            meta: {
              title: computed(() => t('messages.userManagement')),
              permissions: ['ADMIN'],
            },
            component: () => import('../components/UserManagementIndex.vue'),
          },
          {
            path: 'users',
            name: 'settings-users',
            meta: {
              title: computed(() => t('messages.user')),
            },
            component: () => import('../components/SecurityIndex.vue'),
          },
          {
            path: 'ownership',
            name: 'settings-ownership',
            meta: {
              title: computed(() => t('messages.ownership')),
              permissions: ['OWNER'],
            },
            component: () => import('../components/OwnershipIndex.vue'),
          },
        ],
      },

      {
        path: 'user-management/user/:uuid?',
        name: 'settings-user-management-form',
        component: () => import('../components/EnabledUsers/EnabledUserForm.vue'),
        props: true,
      },
      {
        path: 'user-management/digital-coach',
        name: 'settings-digital-coach-form',
        component: () => import('../components/DigitalCoach/DigitalCoachForm.vue'),
        props: true,
      },

      {
        path: 'ownership/transfer',
        name: 'settings-transfer-ownership',
        meta: {
          title: computed(() => t('messages.transferOwnership')),
          permissions: ['OWNER'],
        },
        component: () => import('../components/TransferOwnership/TransferOwnershipIndex.vue'),
      },
    ],
  },
];
